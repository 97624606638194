<template>
  <div class="container">
<div class="topImg">
    <img src="https://web-static-res.bowu66.com/zhelihaowan/static/media/success.e322e6b2.png" alt="">
</div>
<div class="yuyueSuccess">预约成功</div>
<div class="notice">
   <!-- <div class="ddd"> 温馨提示：</div> -->
   <div> {{tips}}</div>
   </div>
<div class="myOrder" @click="myHistory">我的预约记录 ></div>
  </div>
</template>

<script>
import {
 Config
} from "../api/api";
export default {
   data(){
return{

    tips:''
}
   },
   mounted(){
       iCityBridge.setTitle('兴安盟科学技术馆预约');
     Config({ museumId: this.museumId }).then((res) => {
        console.log(res.data.data);
       console.log(res.data.data.bookSuccessTips)
       this.tips=res.data.data.bookSuccessTips
      });
   },
   methods:{
        //我的历史订单
   myHistory(){
       this.$router.push({path:'/historyDetail'})
   },
   }
}
</script>

<style lang="less" scoped>
.container{
    overflow: scroll;
    padding: 0 30px;
    box-sizing: border-box;


    .topImg{
        width: 240px;
        height: 240px;
        box-sizing: border-box;
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom: 50px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .yuyueSuccess{
        width: 100%;
        text-align: center;
        font-size: 40px;
        font-weight: bolder;
        margin:  0 auto;
    }
    .notice{
        margin-top: 50px;
        width: 100%;
        font-size: 24px;
        color: rgba(113, 115, 118, 1);
        text-align: center;
        .ddd{
            width: 100%;
           padding-left: 20px;
            text-align: left;

        }
    }
    .myOrder{
        margin-top: 50px;
        width: 100%;
        text-align: center;
        color: #6492D7;
    }
}


</style>